@import '_variables.scss';
@import '_overrides.scss';
@import '_component.scss';
@import '_mixin.scss';
@import '_print.scss';
@import 'layout';
@import 'header.scss';
@import 'home.scss';
@import 'footer.scss';
@import 'login.scss';
@import 'verification.scss';
@import 'table.scss';

html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'RobotoLight';
  src: url('../resources/fonts/Roboto-Light.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoRegular';
  src: url('../resources/fonts/Roboto-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoMedium';
  src: url('../resources/fonts/Roboto-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}

.hide {
  display: none !important;
}

.box-empty-wrap {
  text-align: center;
  img {
    margin-bottom: 5px;
    margin-top: 25px;
  }
}

a.btn-export:-webkit-any-link {
  color: transparent;
  &:active,
  &:focus {
    color: transparent;
  }
}

.description-pre-line {
  white-space: pre-line;
}

.col-overflow {
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin: 0;
}