.home {
  &__section-banner {
    background-color: $color-primary;
    color: $color-white;
    padding-top: 60px;
    padding-bottom: 100px;
  }

  .section-banner {
    &__right {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;

      .title {
        color: $color-white;
      }

      .description {
        padding-top: 18px;
        opacity: 0.8;
        letter-spacing: 0.25px;
        color: $color-white;
      }

      .button {
        margin-top: 30px;
        background: linear-gradient(129.66deg, #bb9043 0%, #dda74f 64.36%, #bb9043 132.87%);

        &:hover {
          background: linear-gradient(129.66deg, #bb9043 0%, #dda74f 64.36%, #bb9043 132.87%);
          opacity: 0.8;
        }
      }
    }

    &__image {
      width: 100%;
    }
  }

  &__section-description {
    background-color: $color-white;
    padding-top: 90px;
    padding-bottom: 120px;

    .list-card {
      margin-top: 50px;
    }

    .card {
      border: none;

      .MuiCardContent-root {
        padding: 0;
      }

      &__title {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        padding-top: 14px;
        color: $color-baltic-sea;
        font-family: 'RobotoMedium';
        letter-spacing: 0.15px;
      }

      &__thumbnail {
        width: 100%;
        height: auto;
      }

      &__description {
        padding-top: 8px;
        color: $color-dusty-gray;
      }

      &__list {
        color: $color-shuttle-gray;
      }

      &__item {
        font-size: 16px;
      }
    }
  }

  &__section-login {
    background-color: $color-primary;
    text-align: center;
    padding-top: 90px;
    padding-bottom: 100px;

    .section-login {
      &__title {
        color: $color-muddy-waters;
      }

      &__description {
        color: $color-dusty-gray;
        width: 100%;
        max-width: 230px;
        margin: auto;
        padding-top: 8px;
      }

      &__button {
        margin-top: 20px;
      }

      &__link {
        color: $color-white;
        margin-top: 10px;
        text-align: left;

        .MuiLink-root {
          color: $color-white;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .input-container {
      margin: 16px 0;
      border-color: $color-white;
    }

    .phone-number {
      color: $color-white;
    }

    .MuiFormControl-root {
      margin: 16px 0;
      input {
        color: $color-white;
      }

      fieldset {
        border-color: $color-white;
      }
    }
  }

  &__section-about-us {
    padding-top: 90px;
    padding-bottom: 120px;
    display: none;

    .section-about-us {
      &__text {
        padding-top: 24px;
      }
    }
  }

  &__section-subscriber {
    padding-top: 90px;
    padding-bottom: 120px;
    background-color: $color-primary;
    text-align: center;

    .section-subscriber {
      &__title {
        color: $color-muddy-waters;
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
      }

      &__form {
        padding-top: 40px;
      }

      &__input {
        padding-top: 24px;

        .MuiOutlinedInput-notchedOutline,
        .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
          border-color: $color-white;
        }

        input {
          color: $color-white;
        }
      }

      &__button {
        margin-top: 30px;
      }
    }
  }
}

.modal-kyc {
  & > .MuiBox-root {
    text-align: center;
    width: calc(100% - 30px);
    max-width: 530px;
  }

  .section-login {
    &__title {
      color: $color-muddy-waters;
    }

    &__description {
      color: $color-dusty-gray;
      width: 100%;
      max-width: 210px;
      margin: auto;
      padding-top: 8px;
    }

    &__button {
      margin-top: 20px;
    }

    &__button {
      margin-top: 20px;
      @include button-gradient;
    }

    &__link {
      color: $color-muddy-waters;
      margin-top: 10px;
      text-align: left;

      .MuiLink-root {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .MuiFormControl-root,
  .input-container {
    margin: 16px 0;
  }
}

.table-kyc-list {
  .uiTableContainer-root {
  }

  &__description {
    color: $color-dusty-gray;
    width: 100%;
    max-width: 280px;
    margin: auto;
    padding-top: 8px;
    text-align: center;
  }

  .MuiTableContainer-root::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  .MuiTableContainer-root::-webkit-scrollbar:vertical {
    width: 12px;
  }
  .MuiTableContainer-root::-webkit-scrollbar:horizontal {
    height: 12px;
  }
  .MuiTableContainer-root::-webkit-scrollbar-thumb {
    background-color: #bc8f58;
    border-radius: 10px;
    border: 2px solid #ffffff;
  }
  .MuiTableContainer-root::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }

  .input-container {
    margin: 16px 0;

    .phone-number {
      color: $color-dusty-gray;
    }
  }

  .MuiFormControl-root {
    margin: 16px 0;
  }
}

.kyc-detail-history-container {
  .box-empty-wrap {
    border: 1px solid #ececec !important;
  }
}

@media (max-width: $breakpoint-small) {
  .modal-kyc {
    .spring-modal {
      div {
        width: auto;
      }
    }
  }

  .home {
    .section-login__title {
      font-size: 23px;
    }
  }
}

.grid-image {
  img {
    max-width: 270px;
    width: unset !important;
  }
}

.grid-col-image {
  img {
    margin-bottom: 30px;
  }
}
