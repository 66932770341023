.kyc-verification {
  background-color: $color-white;
  padding-top: 30px;

  .MuiStepIcon-root {
    font-size: 3em;
  }

  .MuiStepConnector-alternativeLabel {
    top: 1.5em;
    left: calc(-50% + 2em);
    right: calc(50% + 2em);
  }

  .MuiStepIcon-root {
    &.MuiStepIcon-active,
    &.MuiStepIcon-completed {
      color: $color-muddy-waters;
    }
  }

  .MuiStep-root {
    .MuiTypography-root {
      word-break: keep-all;
    }
  }

  .step {
    &__heading {
      &__name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 30px;

        .text {
          color: $color-muddy-waters;
          font-size: 16px;
        }
      }
    }

    &-container {
      background-color: $color-alabaster;
      padding: 10px 24px 40px;

      .step-form {
        .MuiFormControl-root,
        .MuiBox-root {
          margin-top: 30px;
        }

        .block-default {
          line-height: 300px;
          text-align: center;
          color: $color-shuttle-gray;
          border: 1px dashed rgba(0, 0, 0, 0.12);
          margin-top: 40px;
        }
      }
    }

    &__button {
      text-align: center;
      margin-top: 2em;

      .button-previous {
        margin-right: 1em;
      }
    }
  }
}

.modal-confirm {
  text-align: center;

  .modal-container {
    border: 1px solid $color-muddy-waters;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    padding: 32px 24px;

    .title {
      font-size: 20px;
      line-height: 24px;
      color: $color-baltic-sea;
      font-weight: 500;
      font-family: 'RobotoMedium';
    }

    .description {
      font-size: 14px;
      line-height: 16px;
      color: $color-dusty-gray;
      margin-top: 8px;
    }

    .button {
      margin-top: 2em;
    }

    .button-transparent {
      margin-top: 8px;
    }
  }
}

.webcam-modal {
  .button-gradient {
    border: 1px solid $color-muddy-waters;
    margin-right: 1em;
  }

  video {
    min-width: 540px;
  }
}

@media (max-width: $breakpoint-small) {
  .kyc-verification {
    .MuiStepIcon-root {
      font-size: 2em;
    }

    .MuiPaper-root {
      padding: 32px 0;
    }

    .MuiStepConnector-alternativeLabel {
      top: 12px;
      left: calc(-50% + 30px);
      right: calc(50% + 20px);
    }

    .MuiStepLabel-labelContainer {
      .MuiTypography-root {
        font-size: 12px;
      }
    }

    .step__button {
      display: flex;
      align-items: center;
      flex-direction: column;

      .button,
      button {
        min-width: 150px;
        margin-right: 0;

        &:not(:first-child) {
          margin-top: 20px;
        }
      }
    }
  }
}

.title-form-underline {
  color: $color-muddy-waters;
  font-size: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 12px;
  &.in-verify-form {
    margin-top: 30px;
  }
}

.verify-box-item {
  .title-item {
    font-size: 16px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  div.box-infomation-review {
    height: max-content;
  }
}

.box-form.MuiGrid-container {
  max-width: 700px;
  margin: -12px auto;
  &.box-upload {
    margin-top: 28px;
  }
}
