@page {
  size: A4;
  margin: 0;
}

@media print {
  .kyc-data {
    display: block;
    page-break-after: always;
    width: 100%;
    padding-right: 90px;
  }
}
