.container {
  width: 100%;
  max-width: 1140px;
  margin: auto;
  padding: 0 16px;
}

.page {
  min-height: calc(100vh - 110px);
  background-color: $color-white;
}

.heading {
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;

  &__divider {
    height: 4px;
    margin-top: 6px;
    background-color: $color-primary;
  }
}

.button {
  &.button-primary {
    color: $color-white;
    background-color: $color-muddy-waters;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    &:hover {
      color: $color-white;
      background-color: $color-muddy-waters;
      opacity: 0.8;
    }
  }

  &.button-gradient {
    color: $color-white;
    background: linear-gradient(129.66deg, #bb9043 0%, #dda74f 64.36%, #bb9043 132.87%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    &:hover {
      color: $color-white;
      background: linear-gradient(129.66deg, #bb9043 0%, #dda74f 64.36%, #bb9043 132.87%);
      opacity: 0.8;
    }

    &.Mui-disabled {
      color: $color-white;
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &.button-logout {
    color: $color-white;
    background-color: $color-muddy-waters;
    border-radius: 0;
    font-size: 14px;
    padding: 11.5px;

    &:hover {
      color: $color-white;
      background-color: $color-muddy-waters;
      opacity: 0.8;
    }
  }

  &.button-previous {
    background-color: $color-dusty-gray;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));
    color: $color-white;

    &:hover {
      color: $color-white;
      background-color: $color-dusty-gray;
      opacity: 0.8;
    }
  }

  &.button-transparent {
    background-color: transparent;
    color: $color-muddy-waters;

    &:hover {
      background-color: transparent;
      color: $color-muddy-waters;
      opacity: 0.8;
    }
  }

  &.button-transparent-border {
    background-color: transparent;
    color: $color-muddy-waters;
    border: 1px solid $color-muddy-waters;

    &:hover {
      background-color: transparent;
      color: $color-muddy-waters;
      opacity: 0.8;
    }

    &.Mui-disabled {
      color: $color-muddy-waters;
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &.button-apple {
    background-color: $color-apple;
    color: $color-white;
    border: 1px solid $color-apple;

    &:hover {
      background-color: $color-apple;
      border: 1px solid $color-apple;
      color: $color-white;
      opacity: 0.8;
    }
  }
}

.message {
  &.error-message {
    display: block;
    min-height: 1em;
    margin: 4px 0 16px;
    text-align: left;
    color: $color-burnt-sienna;
  }
}

.input__phone-number {
  &__prefix {
    display: flex;
    align-items: center;
    border-right: 1px solid rgba(0, 0, 0, 0.12);

    span {
      padding: 0.5em;
    }
  }
}

.MuiDialog-root {
  .MuiDialogActions-root {
    display: none;
  }
}

.Mui-disabled:disabled {
  background: #ededed;
  color: #fff;
  border-color: #ededed;
}

[placeholder] {
  text-overflow: ellipsis;
  overflow: hidden;
}
