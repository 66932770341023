@import '../../../styles/variables';

.simple-modal {
    .modal-history-box {
        padding: 24px;
    }
    .modal-history-title {
        font-size: 20px;
        font-weight: 500px;
        text-align: center;
        color: $color-baltic-sea;
        width: 100%;
    }
    .modal-history-header {
        background-color: white;
    }
}

.flex-box-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.box-header {
    background-color: $color-primary;
    min-height: 57px;
    @extend .flex-box-center;
    * {
        color: white !important;
    }
}
.grid-info-request {
    margin-top: 30px;
    > div {
        padding-bottom: 30px;
    }
}

.box-info-value {
    @extend .flex-box-center;
    min-height: calc(100% - 57px);
    border: 1px solid #ececec;
    padding: 5px 16px;
    white-space: pre-line;
    &.colum {
        padding: 0;
        flex-direction: column;
        align-items: unset;
        justify-content: unset
    }
    .box-status {
        @extend .flex-box-center;
        min-height: 52px;
        border-bottom: 1px solid #ececec;
        width: 100%;
    }
    .box-reason {
        @extend .flex-box-center;
        min-height: 48px;
        padding: 5px 16px;
    }
}

.border-info-left {
    border-left: 1px solid #ececec;
    &-no {
        border-left: unset;
    }
}

.border-info-right {
    border-right: 1px solid #ececec;
    &-no {
        border-right: unset;
    }
}

.border-info-bottom {
    border-bottom: 1px solid #ececec;
    &-no {
        border-bottom: unset;
    }
}

.border-info-top {
    border-top: 1px solid #ececec;
    &-no {
        border-top: unset;
    }
}

@media(max-width: 1023px) {
    div.MuiBox-root.modal-history-box {
        // width: 95% !important;
        // max-height: calc(100vh - 150px);
        // overflow: overlay;
        .box-info-value {
            min-height: 102px;
            border: 1px solid #ececec !important;
        }
    }
}

@media (max-width: $breakpoint_large) {
    div.MuiBox-root.modal-history-box {
        width: 95% !important;
        max-height: calc(100vh - 150px);
        overflow: overlay;
        // .box-info-value {
        //     min-height: 102px;
        //     border: 1px solid #ececec !important;
        // }
        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }
        &::-webkit-scrollbar:vertical {
            width: 7px;
        }
        &::-webkit-scrollbar:horizontal {
            height: 7px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #bc8f58;
            border-radius: 5px;
            border: 2px solid #ffffff;
        }
        &::-webkit-scrollbar-track {
            border-radius: 5px;
            background-color: #ffffff;
        }
    }

    .kyc-detail-history-container {
        width: 100%;
        overflow-x: auto;

        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }
        &::-webkit-scrollbar:vertical {
            width: 10px;
        }
        &::-webkit-scrollbar:horizontal {
            height: 10px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #bc8f58;
            border-radius: 7px;
            border: 2px solid #ffffff;
        }
        &::-webkit-scrollbar-track {
            border-radius: 7px;
            background-color: #ffffff;
        }
        .box-empty-wrap {
            border: 1px solid #ececec !important;
        }
    }
    table.table-kyc-history-info {
        min-width: 640px !important;
    }
    .box-empty-wrap {
        min-width: 640px !important;
    }
}
