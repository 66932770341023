$breakpoint_small: 640px;
$breakpoint_medium: 1024px;
$breakpoint_large: 1280px;
$breakpoint_extra_large: 1920px;

$color-white: #fff;
$color-black: #000;
$color-primary: #272a31;
$color-muddy-waters: #bc8f58;
$color-baltic-sea: #181619;
$color-dusty-gray: #959595;
$color-shuttle-gray: #666a74;
$color-azure-radiance: #1181f1;
$color-burnt-sienna: #ef5350;
$color-seance: #9c27b0;
$color-alabaster: #f8f8f8;
$color-apple: #43A047;
$color-gray-6: #F2F2F2;
