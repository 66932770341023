.app-table {
  border-collapse: collapse;
  width: 100%;
  td,
  th {
    border: 1px solid #ececec;
    &.left {
      text-align: left;
    }
    &.center {
      text-align: center;
    }
    &.right {
      text-align: right;
    }
  }
  span.view-item {
    color: $color-azure-radiance;
    margin-left: 7px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: $color-primary;
    color: white;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding: 20px 29px;
    &.unset-border-left {
      border-left: unset;
    }
    &.unset-border-right {
      border-right: unset;
    }
  }
  &.table-kyc-request-detail {
    margin-top: 18px;
    th {
      height: 56px;
      width: 25%;
    }
    td {
      padding: 16px 22px;
    }
  }
  &.table-kyc-history-info {
    margin-top: 24px;
    th {
      height: 28px;
      padding: 8px;
    }
    .col-info-label {
      width: 271px;
      padding: 20px 29px;
    }
    td.info-item {
      padding: 12px 27px;
      min-height: 41px;
      max-width: 250px;
      min-width: 250px;
      word-break: break-all;

      &.old {
        background-color: $color-gray-6;
      }
    }
  }
}

@media (max-width: 1150px) {
  .table-component {
    max-width: 1052px;
    overflow: auto;
  }
}
