.login {
  background-color: $color-white;
  min-height: calc(100vh - 110px);

  &__container {
    max-width: 400px;
    width: 100%;
    margin: auto;
    background-color: $color-white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px 45px;
    text-align: center;
    border: 1px solid $color-muddy-waters;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

    .title {
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: $color-primary;
      font-family: 'RobotoMedium';
    }

    .detail {
      color: $color-dusty-gray;
      padding-top: 8px;

      .text-bold {
        color: $color-muddy-waters;
        font-weight: 500;
      }
    }

    .list {
      display: flex;
      margin-top: 40px;
      justify-content: space-between;

      .MuiButtonBase-root {
        background-color: transparent;
        padding: 20px;
        box-shadow: none;
        border: 2px solid $color-dusty-gray;
      }
      svg path {
        fill: $color-dusty-gray;
      }
      .active {
        border: 2px solid $color-muddy-waters;
        box-shadow: none;

        svg path {
          fill: $color-muddy-waters;
        }
      }

      .item {
        text-align: left;

        &__content {
          text-align: left;
          margin-top: 4px;
          font-size: 10px;
          &.des-option {
            max-width: 124px;
            word-break: break-word;
            text-align: center;
          }
        }
      }
    }

    .button-gradient {
      margin-top: 20px;
    }
  }
}

.otp-code {
  min-height: calc(100vh - 110px);
  position: relative;
  &__container {
    width: 100%;
    max-width: 360px;
    margin: auto;
    background-color: $color-white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .box {
      padding: 50px 45px;
      text-align: center;
      border: 1px solid $color-muddy-waters;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }

    .title {
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: $color-primary;
      font-family: 'RobotoMedium';
    }

    .detail {
      color: $color-dusty-gray;
      padding-top: 8px;
    }

    .time-number {
      color: $color-azure-radiance;
      padding-top: 16px;
      font-size: 20px;
      font-family: 'RobotoMedium';
    }

    .button {
      font-family: 'RobotoMedium';

      &-resend {
        letter-spacing: 1.25px;
        background-color: transparent;
        border: 1px solid $color-muddy-waters;
        box-shadow: none;
        margin-top: 16px;

        &:hover {
          background-color: transparent;
          border: 1px solid $color-muddy-waters;
          opacity: 0.8;
        }
      }

      &-back {
        background-color: transparent;
        border: none;
        color: $color-muddy-waters;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        cursor: pointer;
        margin-bottom: 10px;
        padding: 0;
        font-family: 'RobotoMedium';

        &:focus {
          outline: none;
        }

        .MuiSvgIcon-root {
          margin-right: 1em;
        }
      }
    }

    .otp-number {
      margin: 20px 0 0;

      > div {
        flex: 1;

        &:not(:first-child) {
          margin-left: 10px;
        }
      }

      .input-number {
        width: 100% !important;
        padding: 10px;
        border: 1px solid rgba(0, 0, 0, 0.12);

        &:focus {
          outline-color: $color-muddy-waters;
        }
      }
    }
  }
}

@media (max-width: $breakpoint_small) {
  .login {
    &__container {
      width: calc(100% - 30px);
      padding: 40px 25px;

      .item {
        .MuiButtonBase-root {
          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
