.header {
  background-color: $color-primary;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;

  &__container {
    height: 80px;
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  &__left {
    display: flex;
    align-items: center;

    .logo {
      width: auto;
      height: 32px;
    }
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;

    .list {
      display: flex;
      list-style-type: none;
      margin-right: 100px;

      &__item {
        &:not(:first-child) {
          padding-left: 42px;
        }

        a {
          text-decoration: none;
          color: $color-white;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .button--language {
      margin-left: auto;

      .MuiSelect-select {
        display: flex;
        align-items: center;
        padding: 14px 40px 14px 10px;
        border-radius: 4px;
        color: $color-white;
        font-size: 14px;
        font-weight: 500;
      }

      .language {
        padding-left: 10px;
      }

      .MuiSelect-icon {
        color: $color-white;
      }

      &:hover {
        cursor: pointer;

        .MuiOutlinedInput-notchedOutline {
          border-color: $color-muddy-waters;
          opacity: 0.5;
        }
      }

      .MuiOutlinedInput-notchedOutline,
      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $color-muddy-waters;
        border-width: 1px;
      }
    }

    .MuiListItem-button {
      display: flex;
      align-items: center;
    }

    .button-logout {
      margin-left: 1em;
    }
  }
}

.list--language {
  .language {
    padding-left: 10px;
  }
}

.drawer-menu {
  .MuiPaper-root {
    width: 60vw;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .MuiSelect-select {
    padding: 8px 16px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }

  .list {
    width: 100%;

    &__item {
      text-decoration: none;
      padding: 10px 20px;
      width: 100%;
      color: $color-black;
    }
  }

  .activated {
    background-color: $color-muddy-waters;
    color: $color-white;
    transition: all ease-in-out 0.3s;
  }
}

@media (max-width: $breakpoint_medium) {
  .header {
    &__left {
      .logo {
        width: auto;
        height: 24px;
      }
    }

    &__right {
      button {
        margin-left: auto;
      }

      .button-menu {
        color: $color-muddy-waters;
      }
    }
  }
}

@media (max-width: $breakpoint_small) {
  .header {
    &__left {
      a {
        display: flex;
        align-items: center;
      }

      .logo {
        width: auto;
        height: 16px;
      }
    }

    &__right {
      .button--language {
        .MuiSelect-select {
          padding: 10px 30px 10px 10px;
        }
      }

      .button-logout {
        padding: 8px;
      }
    }
  }

  .list--language {
    .MuiMenuItem-root {
      min-height: 18px;
    }
  }
}
